import React, {  useState } from "react";
import "./dropdown.css";
import Down from "../../assets/arrow.svg";

export default function Dropdown({ val }) {
	const [isActive, setisActive] = useState(false);
	
	

    
	return (
		<div>
			<div className='dropdown'>
				<div className='dropdown-btn' onClick={(e) => setisActive(!isActive)}>
				{ val.question}
					<span className='icon-btn'>
						<img src={Down} alt='' />
					</span>
				</div>
				{isActive && (
					<div className='dropdown-content '>
						<div className="dropdown-item">{val.response}
						{val.eligible ?
							<ul  >
									
								<li>{val.eligible[0] }</li>
								<h4>{val.eligible[1]}</h4>
									<li>{val.eligible[2]}</li>
									<li>{val.eligible[3]}</li>
									<li>{val.eligible[4]}</li>
									<li>{val.eligible[5]}</li>
									<li>{val.eligible[6]}</li>
									<li>{val.eligible[7]}</li>
									<li>{val.eligible[8]}</li>
								</ul> : ""}
							{val.prescription ? 
								<ul >
									<li>{ val.prescription[0]}</li>
									<li>{val.prescription[1] }</li>
									<li>{val.prescription[2]} </li>
									{val.prescription[3]}
								</ul>
								
							: ""}
							{val.lien ?
								<a href={val.lien}>Liens externes</a>: ""}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
