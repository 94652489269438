export const data = [
	{
		id: "3",
		question:
			"Comment savoir si je suis éligible au programme TakeCœur ? Comment s’inscrire ?",
		response:
			"Le programme s’adresse aux patients insuffisants cardiaques sous certaines conditions. Nous vous invitons à vous rapprocher du cardiologue qui définira si vous êtes éligibles au programme. Si vous correspondez aux critères, le cardiologue vous prescrira l’activité de télésurveillance. Il faudra alors que vous nous fassiez parvenir cette ordonnance par courrier. Une fois l’ordonnance reçue, nous prendrons contact avec vous pour vous envoyer les objets connectés, vous expliquer en détail leur utilisation et l’organisation du programme. ",
	},
	{
		id: "3",
		question: "Comment s’organise le programme de télésurveillance ?",
		response:
			"Une fois les objets connectés réceptionnés, il vous sera demandé de porter la montre quotidiennement (jour et nuit) afin de récolter les données sur 24 heures. Il faudra que vous vous pesiez et que vous preniez votre tension une fois par jour, le matin, à jeun. Vous devrez également répondre à un court questionnaire qui vous sera adressé par mail une fois par semaine. Ce questionnaire comprend deux questions pour évaluer de 0 à 10 votre stade d’essoufflement et de fatigue. Grace à toutes ces données collectées, l’infirmier et le cardiologue seront alertés dès les premiers signes de décompensation cardiaque. Ils prendront alors contact avec vous via un appel téléphonique afin d’optimiser la prise en charge médicale. Le programme de télésurveillance ne permet pas la prise en charge d’urgence vitale, mais d’anticiper dans les jours précédant une décompensation cardiaque. En cas de symptômes préoccupants, de malaise cardiaque, vous devez contacter le Samu Centre 15.",
	},
	{
		id: "3",
		question: "Quel est mon interlocuteur privilégié ?",
		response:
			"Votre interlocuteur privilégié sera un infirmier. Il vous suivra et vous expliquera la télésurveillance de TakeCœur. Il établira avec vous également un programme d’éducation thérapeutique (règles hygiéno-diététiques, reconnaissance des signes d’alerte, compréhension du traitement…) afin que vous vous appropriiez mieux votre maladie. Si une alerte est détectée, cet infirmier vous contactera et jugera de la nécessité d’appeler votre cardiologue ou votre médecin traitant. ",
	},
	{
		id: "3",
		question: "En quoi le programme TakeCœur me sera bénéfique ?",
		response:
			"Le but de TakeCœur est de permettre une prise en charge précoce dès les premiers signes d’évolution de la maladie. Il permettra d’éviter la survenue d’une hospitalisation pour insuffisance cardiaque, d’augmenter l’espérance et la qualité de vie. Il aidera également le cardiologue à optimiser votre traitement de l’insuffisance cardiaque.De plus, notre programme inclut une formation personnalisée en éducation thérapeutique, vous permettant de mieux comprendre et d\’agir sur votre maladie.",
	},
	{
		id: "3",
		question: "Dois-je payer pour être télésurveillé ?",
		response:
			"Non, le programme est pris en charge à 100% par la sécurité sociale. Il vous sera cependant demandé un chèque de caution non encaissé d’une valeur de 200€ pour la mise à disposition des objets connectés.",
	},
	{
		id: "1",
		question: "Quels sont les critères d’inclusion des patients ?",
		response:
			"Selon le programme ETAPES (Expérimentations de Télémédecine pour l’Amélioration des Parcours en Santé) : les patients éligibles à un projet de télésurveillance sont ceux présentant un moyen ou haut risque de ré-hospitalisation liée à une nouvelle poussée d’insuffisance cardiaque chronique, selon les critères médicaux décrits ci-après. Le projet de télésurveillance ne doit cependant aucunement reposer sur des dispositifs à finalité rythmologique (l\’évaluation de la télésurveillance de ces dispositifs n\’étant pas incluse dans le périmètre du présent cahier des charges). ",
		eligible:[ "-	sont éligibles à un projet de télésurveillance les patients actuellement en classe NYHA 2 ou plus avec un taux de peptides natriurétiques élevé (BNP > 100 pg/ml ou NT pro BNP > 1 000 pg/ml). ", "Les patients non éligibles à un projet de télésurveillance sont ceux présentant l’une des situations suivantes : " , "-	impossibilité physique ou psychique d’utiliser tous les composants du projet de télésurveillance selon le jugement du médecin désirant inclure le patient dans le projet de télésurveillance. ","-  dialyse chronique.", "-	insuffisance hépatique sévère.", "-	toute pathologie associée existante au jour de l’inclusion, impliquant, selon le médecin incluant le patient une espérance de vie < 12 mois en dehors de l’insuffisance cardiaque chronique. ", "-	compliance ou adhésion thérapeutique habituelle faible estimée selon le médecin incluant le patient.", "-	refus du patient d’avoir un accompagnement thérapeutique. ","-	absence de lieu de séjour fixe. "],
		

	},{
		id: "1",
		question: "Comment s’inscrire dans le programme de télésurveillance TakeCœur?",
		response:
			"Si vous souhaitez intégrer le programme de télésurveillance TakeCœur pour suivre vos patients, il vous suffit de nous contacter via notre site internet ou par mail à contact@takecoeur.com . Nous vous informerons des démarches à effectuer telle que la déclaration type d’activité de télémédecine à adresser à l’ARS et au Conseil Départemental de l’Ordre des Médecins afin que vous puissiez être rémunéré. Une fois ces démarches réalisées, nous vous ferons parvenir les codes d’accès à la plateforme médicale ainsi qu’un pack de télésurveillance comprenant un ordonnancier type et des fiches de renseignements médicales patients. ",
	},{
		id: "1",
		question: "Comment prescrire l’activité de télésurveillance?",
		response:
			"Une fois que vous jugez votre patient éligibible, vous devez réaliser 3 ordonnances (préalablement remplies dans le pack que nous vous enverront) que vous fournirez à votre patient :",
		prescription: ["-	La première ordonnance sera celle que le patient devra adresser au cardiologue qui effectuera la télésurveillance. S’il s’agit de vous, celle ordonnance n’est pas nécessaire.","-	La seconde ordonnance devra être adressée par le patient à TakeCœur afin que nous puissions lui faire parvenir les différents objets connectés directement à son domicile.", "-	La troisième ordonnance est celle pour l’accompagnant thérapeutique. ","Si vous disposez d’une unité de télésurveillance au sein de votre structure qui peut prendre en charge cette mission, vous pouvez directement lui adresser cette dernière ordonnance. Si ce n’est pas le cas, le patient devra adresser l’ordonnance à TakeCœur qui lui proposera un infirmier référent affilié.","Une courte fiche de renseignements médicale patient sera également à remplir, à remettre au patient, qu’il nous adressera avec les autres documents."],
	},{
		id: "1",
		question: "Comment s’organise la télésurveillance TakeCœur ? ",
		response:
			"Une fois les objets connectés réceptionnés par le patient, il lui sera demandé de porter la montre quotidiennement (jour et nuit) afin de récolter les données sur 24 heures. Il devra également se peser et prendre sa tension une fois par jour. Un questionnaire hebdomadaire lui sera adressé dans lequel il lui sera demandé de renseigner son échelle de dyspnée et d’asthénie. Grace à toutes ces données collectées, des alertes seront alors déclenchées en cas de prédiction forte de prédiction de décompensation cardiaque. Après reception de l’alerte, l’infirmier référent contactera le patient afin de confirmer sa pertinence. Il s’appuiera sur des arbres décisionnels développés par le comité scientifique de TakeCœur afin de savoir quand il doit vous contacter ou son médecin traitant. En cas de doute, celui-ci pourra contacter un médecin membre du comité expert afin d’aiguiller sa prise en charge. Cela permettra d’affiner la pertinence des alertes que vous recevrez. Si une alerte vous parvient, vous pourrez alors adapter la prise en charge de votre patient à votre convenance (avancement de date de rendez-vous,  modification de  traitement…). Une attention particulière sera apportée par Takecœur à la prise en charge des alertes critique. En effet, le patient sera invité à contacter directement le Centre 15 en cas de symptôme(s) préoccupant(s).",
	},{
		id: "1",
		question: "Participer à cette télésurveillance est-elle payante ? Comment serais-je rémunéré ?",
		response:
			"Non, l’intégration à la solution TakeCœur est totalement gratuite. Aucune redevance ne vous sera demandée. L’encadrement de votre rémunération est fixé par le programme ETAPES. Des informations complémentaires sont disponibles sur le site du Ministère des Solidarités et de la Santé ",
		lien: "https://solidarites-sante.gouv.fr/soins-et-maladies/prises-en-charge-specialisees/telesante-pour-l-acces-de-tous-a-des-soins-a-distance/article/la-telesurveillance-etapes",
	}];
