import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Loading from "./Components/Loading";
import FAQ from "./Pages/FAQ/FAQ";
import Contact from "./Pages/Contact/Contact";
import { Mentions } from "./Pages/Mentions/Mentions";
import Plateform from "./Pages/Plateform/Plateform";

function App() {
	const Acceuil = lazy(() => import("./Pages/Accueil/Acceuil"));

	return (
		<div className='App'>
			<Suspense fallback={<Loading />}>
				<Header />
				<Routes>
					<Route path='/' element={<Acceuil />} />
					<Route path='/FAQ' element={<FAQ />} />
					<Route path='/Contact' element={<Contact />} />
					<Route path='/Mentions-legales' element={<Mentions />} />
					<Route path='/Plateform' element={<Plateform />} />
					<Route path='/Plateform' element={<Plateform />} />
				
				</Routes>
				<Footer />
			</Suspense>
		</div>
	);
}

export default App;
