import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { data } from "../../data/questions";
import Search from "../../assets/search.png";
import "./faq.css";
export default function FAQ() {
	const [input, setInput] = useState("");
	const [output, setOutput] = useState([]);
	const [choice, setChoice] = useState(false);

	const handleChoice = (state) => {
		setChoice(!state);
	};
	useEffect(() => {
		setOutput([]);

		data.filter((val) => {
			if (
				val.response.toLowerCase().includes(input.toLowerCase()) ||
				val.question.toLowerCase().includes(input.toLowerCase())
			) {
				setOutput((output) => [...output, val]);
			}
		});
	}, [input]);
	return (
		<div>
			<div className='faq'>
				<div className='faq-left'>
					<img src='' alt='' />
				</div>
				<div className='faq-right'>
					<h3>FAQ</h3>
					<div className='faq-content'>
						<div className='search-bar'>
							<input
								type='search'
								placeholder='Poser une question'
								onChange={(e) => setInput(e.target.value)}
							/>
							<button>
								<img src={Search} alt='' />
							</button>
						</div>
						<h3>Questions les plus fréquentes </h3>
						<div className='choice'>
							<p
								onClick={() => handleChoice(choice)}
								className={choice ? "" : "active"}>
								Particuliers{" "}
							</p>
							<hr />
							<p
								onClick={() => handleChoice(choice)}
								className={choice ? "active" : ""}>
								Professionnels{" "}
							</p>
						</div>

						<div className='questions'>
							{output.map((val, index) => {
								if (choice === false && val.id === "3") {
									return <Dropdown key={index} val={val} />;
								} else if (choice === true && val.id === "1") {
									return <Dropdown key={index} val={val} />;
								}
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
