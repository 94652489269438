import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";
// Assets et style
import Logo from "../../assets/LogoBlack.svg";

import LogoWhite from "../../assets/LogoWhite.svg";

import "./header.css";

export default function Header() {
	/*document.addEventListener("scroll", () => {
	     const family
		if (window.innerWidth < 426) {
			let value = window.scrollY / 3.3;
			family.style.backgroundPosition = "-150px " + (1800 - value) + "px";
			family.style.backgroundSize = "160% 75%";
		} else {
			let value = window.scrollY / 2.5;
			family.style.backgroundSize = "100% 100%";
			family.style.backgroundPosition = "0px " + (2100 - value) + "px";
		}
	});*/

	const containerVariants = {
		hidden: {
			opacity: 0,
		},
		visible: {
			opacity: 1,
			transition: {
				staggerChildren: 0.5,
			},
		},
	};
	const childVariants = {
		hidden: {
			opacity: 0,
			x: -15,
		},
		visible: {
			opacity: 1,
			x: 0,
		},
		transition: {
			duration: 2,
			type: "spring",
			stiffness: 120,
		},
	};
	const [toggle, setToggle] = useState(false);

	const handleChange = (event) => {
		return setToggle(!event);
	};

	/*************************** Scroll nav top ********************************/
	const [changeBackground, setchangeBackground] = useState(false);
	const scrolY = () => setchangeBackground(window.scrollY > 40);

	useEffect(() => {
		window.addEventListener("scroll", scrolY);

		return () => window.removeEventListener("scroll", scrolY);
	}, [changeBackground, scrolY]);

	return (
		<header className={changeBackground ? "active" : ""}>
			<nav>
				<Link to='/'>
					<motion.img
						src={changeBackground ? Logo : LogoWhite}
						alt='TakeCoeur'
						className='LogoImg'
						height={80}
						initial={{ y: -450 }}
						animate={{ y: 0 }}
						transition={{ duration: 0.5, type: "spring", stiffness: 120 }}
					/>
				</Link>
				<motion.div>
					<motion.span
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 1 }}
						onClick={() => handleChange(toggle)}
						className={toggle ? "active" : ""}>
						<i></i>
					</motion.span>
					<motion.ul
						variants={containerVariants}
						initial={"hidden"}
						animate={
							window.innerWidth > 990 ? "visible" : toggle ? "visible" : ""
						}
						className={changeBackground ? "active" : ""}
						style={{
							height: toggle ? "250%" : window.innerWidth < 990 ? "0" : "100%",
						}}>
						<motion.li variants={childVariants}>
							<Link
								onClick={() => handleChange(toggle)}
								to='/'
								className='navlink hover-underline-animation'>
								Accueil
							</Link>
						</motion.li>
						<motion.li variants={childVariants}>
							<Link
								 whileHover={{ scale: 1.2 }}
								onClick={() => handleChange(toggle)}
								to='/FAQ'
								className='navlink hover-underline-animation'>
								F.A.Q
							</Link>
						</motion.li>
						<motion.li
							onClick={() => handleChange(toggle)}
							variants={childVariants}>
							<Link
								to='/Contact'
								className='navlink hover-underline-animation '>
								Contact
							</Link>
						</motion.li>
					</motion.ul>
				</motion.div>
			</nav>
		</header>
	);
}
