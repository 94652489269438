import React from "react";

import "./contact.css";
export default function Contact() {

	return (
		<div>
			<div className='contact-page'>
				<div id='contact'>
					<h3>Je souhaite recevoir plus d'informations</h3>
					<hr />
					<div className='form-contact'>
						<div className='form-contact-content'>
							<form method='post' action="https://contact.takecoeur.com/" id="demo-form">
								<div className='identifiant'>
									<div className='form-text'>
										<label htmlFor='Nom'>Nom</label>
										<input name="nom" type='text' placeholder='votre nom' required/>
									</div>
									<div className='form-text'>
										<label htmlFor='prenom'>Prénom</label>
										<input name="prenom" type='text' placeholder='votre prénom' required/>
									</div>
								</div>
								<div className='coordonees'>
									<div className='form-text'>
										<label htmlFor='number'>Numéro de téléphone</label>
										<input  name="number" type='number' placeholder='xxxxxxxx45'required />
									</div>
									<div className='form-text'>
										<label htmlFor='email'>Email</label>
										<input name="email"type='email' placeholder='xxxxxxx@gmail.com'required />
									</div>
								</div>
								<div className='message'>
									<div className='form-text'>
										<label htmlFor='message'>Message</label>
										<textarea
											required
											name='message'
											id='message'
											cols='30'
											rows='10'></textarea>
									</div>
								</div>
								
								<div class="g-recaptcha" data-sitekey="6LfD-XceAAAAANPQ0HpZ3ODV_r1MIl3-wcs6u-Cz"></div>
									<button type='submit'>Envoyer</button>
							
							</form>
							<img src='' alt='' />
						</div>
					</div>
				</div>
				<div className='contact-right'></div>
			</div>
		</div>
	);
}
