import React from "react";
import "./mentions.css";
export const Mentions = () => {
	return (
		<div className='mentions'>
			<div className='mentions-container'>
				<h3>Mentions Légales</h3>
				<div className='mentions-content'>
					<p>
						Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004
						pour la confiance en l’économie numérique
					</p>
					<h3>Edition du site</h3>
					<p>
						Le site TakeCoeur est édité par la société TakeCoeur qui est en
						cours de création
					</p>
					<h3>Responsable de publication</h3>
					<p>C-Design</p>
					<h3>Nous Contacter</h3>
					<p>contact@takecoeur.com</p>
					<h3>Hébergeur</h3>
					<p>
						Le site TakeCoeur est héberger par{" "}
						<a href=' https://www.ionos.fr/apropos'>
							{" "}
							https://www.ionos.fr/apropos
						</a>{" "}
					</p>
				</div>
			</div>
		</div>
	);
};
