import React from 'react'

import "./plateform.css"
const Plateform = () => {
  return (
      <div className='plateform'>
           <h1>En cours de construction</h1>
          <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_wv0g588k.json"  background="transparent"  speed="1"  style={{width: '50%'  }} loop autoplay></lottie-player>
      </div>
  )
}

export default Plateform